<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Fee Title Wise Collection Report
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
              labelExcel="Download Excel"
              labelPdf="Download Pdf"
            ></print-buttons>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm3>
                <v-calendar-field
                  id-val="from_date"
                  v-model="frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs3 sm3>
                <v-calendar-field
                  id-val="to_date"
                  v-model="toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs3 sm3>
                <transaction-via v-model="transaction_by"></transaction-via>
              </v-flex>
              <search-button
                :disabled="transaction_by.length < 1"
                style=""
                permission="receipt-read"
                @action="get()"
              >
                Search
              </search-button>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            hide-default-footer
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr @click="expanded = !expanded" style="cursor: pointer;">
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.fee_head }}
                </td>
                <td class="text-xs-left">
                  {{ item.sum_amount.currency() }}
                </td>
                <td class="text-xs-left">
                  {{ parseFloat(item.sum_instant_discount).currency() }}
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr v-if="totalSum > 0">
                <td :colspan="headers.length - 2">
                  <strong>Total:</strong>
                </td>
                <td>
                  {{
                    (
                      totalSum -
                      (totalInstantDiscount - totalInstatanDiscountSum)
                    ).currency()
                  }}
                </td>
                <td>
                  {{
                    (
                      totalInstatanDiscountSum +
                      (totalInstantDiscount - totalInstatanDiscountSum)
                    ).currency()
                  }}
                </td>
              </tr>
              <tr v-if="totalInstatanDiscountSum < totalInstantDiscount">
                <td :colspan="3">
                  <strong>
                    Bulk Discount of
                    {{
                      (
                        totalInstantDiscount - totalInstatanDiscountSum
                      ).currency()
                    }}
                    was given</strong
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import TransactionVia from "@/components/app/TransactionVia";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  components: {
    TransactionVia,
  },
  data: () => ({
    transaction_by: ["Cash", "Cheque", "Khalti", "Esewa"],
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/eod-collection"
    ),
    pagination: {
      rowsPerPage: 100,
      descending: true,
    },
    frmDate:
      nd.getYear() + "-" + nd.getMonth().pad() + "-" + nd.getDate().pad(),
    toDate: nd.format("YYYY-MM-DD"),
    downloadUrl: "",
    totalSum: 0,
    totalInstatanDiscountSum: 0,
    month: "",
    rowsPerPageItems: [5, 10, 25, 50, 75],
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Fee Head", align: "left", value: "fee_head" },
      { text: "Amount", align: "left", value: "amount", width: 200 },
      {
        text: "Instant Discount",
        align: "left",
        value: "instant_discount",
        width: 150,
      },
    ],
    totalInstantDiscount: 0,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    month: function(value) {
      this.form.items.data = [];
    },
  },

  methods: {
    get() {
      let extraParam =
        "fromDate=" +
        this.frmDate +
        "&toDate=" +
        this.toDate +
        "&reporting_type=eod-collection";

      let via =
        this.transaction_by.length > 3 ? "all" : this.transaction_by.join(",");

      extraParam += "&via=" + via;

      this.form.get(null, this.queryString(extraParam)).then(({ data }) => {
        this.totalSum = 0;
        this.totalInstatanDiscountSum = 0;
        this.totalInstantDiscount = 0;
        if (data.cash_cheque && data.cash_cheque.length) {
          const cash_cheque = data.cash_cheque[0];
          this.totalInstantDiscount = cash_cheque.instant_discount;
        }
        data.data.map((res) => {
          this.totalSum += parseFloat(res.sum_amount);
          this.totalInstatanDiscountSum += parseFloat(res.sum_instant_discount);
        });
        this.pagination.totalItems = data.meta.total;
        this.downloadUrl = data.download_url;
      });
    },

    viewStatement(sod_date) {
      this.$router.push({
        name: "eod-collection-report-detail",
        params: {
          sod: sod_date,
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
