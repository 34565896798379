<template>
  <v-select
    v-model="transaction_by"
    :items="transaction_type"
    label="Transaction Via"
    multiple
    class="pt-0"
    outlined
    dense
  >
    <v-list-item slot="prepend-item" ripple @click="toggle">
      <v-list-item-action>
        <v-icon :color="transaction_by.length > 0 ? 'indigo darken-4' : ''"
          >{{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-title>All</v-list-item-title>
    </v-list-item>
    <v-divider slot="prepend-item" class="mt-2"></v-divider>
    <v-divider slot="append-item" class="mb-2"></v-divider>
  </v-select>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      transaction_by: [],
      transaction_type: ["Cash", "Cheque", "Khalti", "Esewa", "Advance"],
    };
  },
  watch: {
    transaction_by: function() {
      this.$emit("input", this.transaction_by);
    },
  },
  computed: {
    selectAllTVia() {
      return this.transaction_type.length === this.transaction_by.length;
    },
    icon() {
      if (this.selectAllTVia) return "check_box";
      return "add_box";
    },
  },
  mounted() {
    if (this.value) this.transaction_by = this.value;
    else this.transaction_by = [];
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllTVia) {
          this.transaction_by = [];
        } else {
          this.transaction_by = this.transaction_type.map((type) => {
            return type;
          });
        }
      });
    },
  },
};
</script>
<style lang="scss"></style>
